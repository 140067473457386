/* Ensure the entire body takes full height and centers content vertically */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the body spans the full viewport height */
}

/* Main App container with flexible layout */
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Header that takes vertical space, centering content */
.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-grow: 1; /* Makes the header take available vertical space */
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: #282c34;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}

button:disabled {
  background-color: #9ec6e8;
  cursor: not-allowed;
}

p {
  color: red;
  font-size: 0.9rem;
}

/* Footer section fixed to the bottom of the screen */
.App-footer {
  background-color: #20232a;
  color: #61dafb;
  text-align: center;
  padding: 10px;
  width: 100%;
  position: relative;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
}

.App-footer a:hover {
  text-decoration: underline;
}